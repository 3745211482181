/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    /**
     * Universal click or tap event
     * (replacement for 'click')
     */

    var clickEvent = function () {
        return ('undefined' !== typeof window.touchstart ? 'touchstart' : 'click');
    };

    var winWidth    = $(window).width(),
        mobile      = false,
        mobilePhone = false;

    /**
    * Mobile
    * Window width
    */

    function mobileCheck() {
        if ( '767' >= winWidth ) {
            mobile = true;
        } else {
            mobile = false;
        }

        if ( '575' >= winWidth ) {
            mobilePhone = true;
        } else {
            mobilePhone = false;
        }
    }

    mobileCheck();

    $(window).resize(function() {
        winWidth = $(window).width();
        mobileCheck();
    });

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Delta = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                /**
                 * Site header
                 */

                 var siteHeaderStyle = function() {
                     if ( 10 <= $(document).scrollTop() ) {
                         $('#site-header').addClass('is-not-top');
                     } else {
                         $('#site-header').removeClass('is-not-top');
                     }
                 };

                 siteHeaderStyle();

                $(window).on('scroll', function() {
                    siteHeaderStyle();
                });


                /**
                 * Hero
                 */

                $('.home .hero .hero-text').addClass('animated fadeInDown');


                /**
                 * Site nav
                 */

                var closePrimaryNavSubMenus = function($this) {
                    if ( ! $this ) {
                        $('#menu-primary .sub-menu-active').removeClass('sub-menu-active');
                        $('#menu-primary .sub-menu').velocity('slideUp');
                    } else {
                        $('#menu-primary .sub-menu-active').not($this).removeClass('sub-menu-active');
                        $('#menu-primary .sub-menu').not($this.children('.sub-menu'))
                            .velocity('slideUp');
                    }
                },

                openPrimaryNavSubMenus = function($this) {
                    closePrimaryNavSubMenus($this);

                    $this.addClass('sub-menu-active');

                    $this.children('.sub-menu')
                        .velocity('slideDown');
                },

                closePrimaryNav = function() {
                    closePrimaryNavSubMenus();
                    $('body').removeClass('mobile-nav-is-active');
                    $('#site-nav .nav-trigger')
                        .removeClass('active');
                    $('#site-nav .nav-trigger').children('i')
                        .removeClass('icon-cross')
                        .addClass('icon-menu');
                },

                openPrimaryNav = function() {
                    $('body').addClass('mobile-nav-is-active');
                    $('#site-nav .nav-trigger')
                        .addClass('active');
                    $('#site-nav .nav-trigger').children('i')
                        .removeClass('icon-menu')
                        .addClass('icon-cross');
                };

                /* Not mobile */

                if ( ! mobile ) {

                    $(document).on({
                        mouseenter: function(e) {
                            $(this).children('.sub-menu').velocity('slideDown', { duration: 250 });
                            e.stopPropagation();
                        },
                        mouseleave: function(e) {
                            $(this).children('.sub-menu').velocity('slideUp', { duration: 150 });
                            e.stopPropagation();
                        }
                    }, '#menu-primary > li.menu-item');

                }

                /* Mobile */

                // Open

                $(document).on(clickEvent(), '#site-nav .nav-trigger', function(e) {
                    e.preventDefault();
                    if ( ! $('body').hasClass('mobile-nav-is-active') ) {
                        openPrimaryNav();
                    }
                });

                // Close on body click

                $(document).on(clickEvent(), 'body.mobile-nav-is-active', function(e) {
                    if ( $(e.target) !== $('#menu-primary') &&
                        ! $(e.target).parents('#menu-primary').length ) {
                        closePrimaryNav();
                        closePrimaryNavSubMenus();
                    }
                });

                // Submenu expand

                if ( mobile ) {

                    $(document).on(clickEvent(), '.menu-item-has-children .expand', function(e) {
                        if ( ! $(this).parent().hasClass('sub-menu-active') ) {
                            closePrimaryNavSubMenus($(this).parent());
                            openPrimaryNavSubMenus($(this).parent());
                        } else {
                            closePrimaryNavSubMenus();
                        }
                    });

                }


                /**
                 * Site search
                 */

                var closeSearch = function() {
                    $('body').removeClass('search-is-active');
                    $('#site-search input.search-field').blur();
                },

                openSearch = function() {
                    $('body').addClass('search-is-active');
                    $('#site-search input.search-field').focus();
                },

                closeMobileSearch = function() {
                    $('body').removeClass('mobile-search-is-active');
                },

                openMobileSearch = function() {
                    $('body').addClass('mobile-search-is-active');
                };

                /* Not mobile */

                $(document).on(clickEvent(), '#menu-primary .menu-item.search a', function(e) {
                    e.preventDefault();
                    openSearch();
                });

                $(document).on(clickEvent(), 'body.search-is-active', function(e) {
                    if ( ! $(e.target).parents('#site-search').length ||
                        $(e.target).parents('#site-search .close').length
                    ) {
                        closeSearch();
                    }
                });

                /* Mobile */

                // Open

                $(document).on(clickEvent(), '#site-nav .search-trigger', function(e) {
                    e.preventDefault();
                    if ( ! $('body').hasClass('mobile-search-is-active') ) {
                        openMobileSearch();
                    }
                });

                // Close on body click

                $(document).on(clickEvent(), 'body.mobile-search-is-active', function(e) {
                    if ( ! $(e.target).parents('#site-search').length ||
                       $(e.target).parents('#site-search .close').length
                    ) {
                        closeMobileSearch();
                    }
                });

                /* Window resize */

                $(window).resize(function() {

                    if ( ! mobile ) {
                        $('body')
                            .removeClass('mobile-nav-is-active')
                            .removeClass('mobile-search-is-active');
                        closePrimaryNavSubMenus();
                    }

                });

            },
            finalize: function() {

                /**
                 * Isotope - Recipes
                 */

                if ( $('.recipes-grid').length ) {

                    $('.recipes-grid').isotope({
                        layoutMode:      'packery',
                        packery: {
                            columnWidth: '.grid-sizer',
                            gutter:      '.gutter-sizer'
                        },
                        itemSelector:    '.recipe',
                        percentPosition: true,
                        stagger:         30,
                        hiddenStyle: {
                            opacity:   0,
                            transform: 'scale(0.001)'
                        },
                        visibleStyle: {
                            opacity:   1,
                            transform: 'scale(1)'
                        }
                    });

                }

            }
        },

        // Home page
        'home': {
            init: function() {

                /**
                 * Waypoints + animations
                 */

                // Featured products fan out

                // var featuredProductsWp = new Waypoint({
                //     element: document.getElementById('featured-products'),
                //     handler: function(direction) {
                //         $('#featured-products').addClass('active');
                //     },
                //     offset: '65%'
                // });

                // New look tag

                // var newLookWp = new Waypoint({
                //     element: document.getElementById('featured-products'),
                //     handler: function(direction) {
                //         $('#featured-products .new-look').addClass('active');
                //     },
                //     offset: '60%'
                // });

                var featuredProductsAnimate = function() {
                    if ( 10 <= $(document).scrollTop() ) {
                        $('#featured-products').addClass('active');
                    }

                    if ( 50 <= $(document).scrollTop() ) {
                        $('#featured-products .new-look').addClass('active');
                    }
                };

                featuredProductsAnimate();

               $(window).on('scroll', function() {
                   featuredProductsAnimate();
               });


                // Main categories CTAs fade in
                var $categories      = $('#category-ctas .category'),
                    categoriesCtasWp = [],
                    time             = 500;

                $categories.each(function(i) {
                    categoriesCtasWp[i] = new Waypoint({
                        element: $(this)[0],
                        handler: function(direction) {
                            $(this.element).addClass('animated fadeInUp');
                        },
                        offset: '75%'
                    });

                    time += 500;
                });

                // Buy now

                var buyNowWp = new Waypoint({
                    element: document.getElementById('buy-now'),
                    handler: function(direction) {
                        $('#buy-now').addClass('animated fadeInUp');
                    },
                    offset: '75%'
                });

                // Recipes header

                var recipesHeaderWp = new Waypoint({
                    element: document.getElementById('recipes-cta'),
                    handler: function(direction) {
                        $('#recipes-cta .section-header').addClass('active');
                    },
                    offset: '50%'
                });

            },
            finalize: function() {
            }
        },

        'archive': {
            init: function() {

                // Larger image sizes for not mobile

                var imgResizer = function($imgContainer) {

                    $imgContainer.each(function(i) {
                        var $mdImgUrl = $(this).data('image-md');

                        if ( ! mobile && $mdImgUrl ) {
                            $(this).css('background-image', 'url(' + $mdImgUrl + ')');
                        }
                    });

                };

                imgResizer($('.image-box-image'));

                $(window).resize(function() {
                    imgResizer($('.image-box-image'));
                });

            },

            finalize: function() {
            }
        },

        'single': {
            init: function() {

                // Buy now

                if ( $('#buy-now').length ) {

                    var buyNowWp = new Waypoint({
                        element: document.getElementById('buy-now'),
                        handler: function(direction) {
                            $('#buy-now').addClass('animated fadeInUp');
                        },
                        offset: '75%'
                    });

                }

                // Recipe title move

                var moveRecipeTitle = function() {
                    if ( mobile ) {
                        $('.recipe-header').prependTo('.recipe-image-col');
                    } else {
                        $('.recipe-header').prependTo('.recipe-content-col .recipe-content');
                    }
                };

                if ( $('.recipe-header').length ) {

                    moveRecipeTitle();

                    $(window).resize(function() {
                        moveRecipeTitle();
                    });

                }

            },

            finalize: function() {

                // Heat rating

                if ( $('#heat-rating').length ) {

                    var heatRatingWp = new Waypoint({
                        element: document.getElementById('heat-rating'),
                        handler: function(direction) {
                            $('#heat-rating').addClass('active');
                        },
                        offset: '75%'
                    });

                }


                /* Carousels */

                // Related products/recipes carousels

                $('.related-carousel').owlCarousel({
                    items:         3,
                    autoplay:      false,
                    loop:          true,
                    nav:           true,
                    navText:       [
                        '<i class="icon-chevron-left"></i><span class="sr-only">Next</span>',
                        '<span class="sr-only">Previous</span><i class="icon-chevron-right"></i>'
                    ],
                    mouseDrag:     false,
                    responsive:    {
                        0: {
                            items: 1
                        },
                        768: {
                            items: 3
                        }
                    }
                });

                // Prev/next flips by page, instead of 1 by 1

                var goToNextCarouselPage = function() {
                    var $dots = $('.related-carousel .owl-dot'),
                        $next = $dots.filter('.active').next();

                    if (! $next.length) {
                        $next = $dots.first();
                    }

                    $next.trigger('click');
                };

                if ( ! mobile ) {
                    $('.owl-prev, .owl-next').on(clickEvent(), function(e) {
                        console.log('bar');
                        goToNextCarouselPage();
                    });
                }

            }
        }

    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Delta;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
